<template>
  <div class="view pa24">
<!--    <div>-->
<!--      <el-button class="ma" type="primary" @click="add"-->
<!--      >+ 添加开发区</el-button-->
<!--      >-->
<!--    </div>-->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" style="margin-top: 30px">
      <el-form-item label="开发区名称" prop="title">
        <el-input
            type="text"
            placeholder="请输入开发区名称"
            maxlength="50"
            v-model="ruleForm.title"
            style="width: 400px"
            show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item label="类型" prop="typeId">
        <el-select
            clearable
            v-model="ruleForm.typeId"
            placeholder="请选择类型"
            style="width: 400px"
        >
          <el-option
              v-for="item in typeList"
              :key="item.kfqTypeId"
              :label="item.typeName"
              :value="item.kfqTypeId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地理位置" prop="address">
        <el-input
            @blur="blurAddress"
            id="suggestId"
            type="text"
            placeholder="请输入地址"
            v-model="ruleForm.address"
            maxlength="50"
            @change="theLocation"
            style="width: 400px"
            show-word-limit
        />
        <div
            id="baidumap"
            style="width: 600px; height: 400px; margin-top: 10px;"
        ></div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveForm()">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
let ac = ''
import {mapState} from "vuex";
import {selectKfqTypePageList,addKfqArea,upKfqArea,queryKfqAreaById} from "@/api/serviceDevelopmentZone";
export default {
  name: "addFKQ",
  data() {
    return {
      activeName:'first',
      typeList:[],
      ruleForm: {
        title:"",
        typeId: "",
        address: "",
        gps:''
      },
      fileList: [],
      rules: {
        title: [{ required: true, message: "请输入开发区名称" }],
      },
      radio: "",
      options: [],
      dialogImageUrl: "",
      dialogVisible: false,
      map: {}, //赋值地图
      local: {}, //搜索
      ossUrl: this.$store.state.ossUrl,
      uploadUrl: this.$store.state.uploadingUrl,
      kfqRegionId:'',
      kfqAreaId:''
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  created() {
    const { kfqAreaId } = this.$route.query;
    if (kfqAreaId) {
      this.queryDetails(kfqAreaId);
    }
  },
  mounted() {
    this.kfqRegionId = this.$route.query.kfqRegionId
    this.createdMap();
    this.toTypeList();
  },
  beforeDestroy() {
    ac.removeEventListener("onConfirm", this.setValue);
  },
  methods: {
    toTypeList(){
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        pageSize:9999,
        pageNum: 1,
      }
      selectKfqTypePageList(ajax)
          .then((res) => {
            if (res.data) {
              const { total, list } = res.data.pageInfo;
              this.typeList = list;
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    ready() {
      Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
    },
    onEditorChange(e){
      if(this.ruleForm.details==''){
        this.TiLength=0
      }else{
        this.TiLength=e.quill.getLength()-1
      }
    },
    /**@method 创建地图 */
    createdMap() {
      let _this = this
      let navigationControl = new BMap.NavigationControl({
        // 靠左上角位置
        anchor: BMAP_ANCHOR_TOP_LEFT,
        // LARGE类型
        type: BMAP_NAVIGATION_CONTROL_LARGE,
        // 启用显示定位
        enableGeolocation: true,
      });
      _this.map = new BMap.Map("baidumap");
      _this.map.centerAndZoom("北京", 12);
      _this.map.addControl(navigationControl);
      ac = new BMap.Autocomplete({
        //建立一个自动完成的对象
        input: "suggestId",
        location: this.map,
      });
      _this.map.addEventListener("click",function (e){
        _this.map.clearOverlays(); //清除地图上所有覆盖物
        console.log('0000')
        console.log(e)
        _this.map.addOverlay(new BMap.Marker(e.point)); //添加标注
        _this.ruleForm.gps = JSON.stringify(e.point)
      });
    },
    setValue(e) {
      const {
        item: { value },
      } = e;
      console.log(this.ruleForm.address )
      this.ruleForm.address = `${value.city}${value.district}${value.business}`;
    },
    myFun() {
      let pp = this.local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
      this.map.centerAndZoom(pp, 18);
      this.map.addOverlay(new BMap.Marker(pp)); //添加标注
      this.ruleForm.gps = JSON.stringify(pp);
    },
    theLocation(val) {
      this.map.clearOverlays(); //清除地图上所有覆盖物
      this.local = new BMap.LocalSearch(this.map, {
        //智能搜索
        onSearchComplete: this.myFun,
      });
      this.local.search(val);
    },
    blurAddress(){
      let a = document.getElementById('suggestId').value;
      console.log(a.length)
      if (a.length>3) {
        this.theLocation(a);
      }
    },
    /**@method 获取编辑数据 */
    async queryDetails(id) {
      const result = await queryKfqAreaById({ kfqAreaId:id, associationId: localStorage.getItem("associationId"), });
      console.log(result)
      const {
        name,
        kfqAreaId,
        position,
        type,
      } = result.data;

      this.kfqAreaId = kfqAreaId
      this.ruleForm.title = name
      this.ruleForm.typeId = type
      this.ruleForm.address = position
      let point =  {lng: result.data.longitude, lat: result.data.latitude}
      this.ruleForm.gps = JSON.stringify(point)
      if (this.ruleForm.gps) {
        this.theLocation(position);
      }
    },
    /**@method 发布 */
    saveForm() {
      let a = document.getElementById('suggestId').value;
      if ( a == '') {
        this.$message({
          showClose: true,
          message: "请选择地址",
          type: "warning",
        });
        return;
      }else {
        let gps = JSON.parse(this.ruleForm.gps)
        let data = {
          associationId: localStorage.getItem("associationId"),
          type:this.ruleForm.typeId,
          name:this.ruleForm.title,
          position:a,
          longitude:gps.lng,
          latitude:gps.lat,
          kfqRegionId:this.kfqRegionId
        };
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            if (this.kfqAreaId) {
              //保存编辑
              await upKfqArea(
                  { ...data, kfqAreaId:this.kfqAreaId },
                  { showLoading: true }
              );
            } else {
              data.ifTop = 1
              //添加请求
              await addKfqArea(data, { showLoading: true });
            }
            this.$router.push({ path: "/allFWFKQList" ,query:{kfqRegionId:this.kfqRegionId}});
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

